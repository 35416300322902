import { DeckModel } from './deck';
import { CardSuit } from './card';

export const newTamariz: () => DeckModel = () => {
  return [
    { number: 4, suit: CardSuit.Clubs },
    { number: 2, suit: CardSuit.Hearts },
    { number: 7, suit: CardSuit.Diamonds },
    { number: 3, suit: CardSuit.Clubs },
    { number: 4, suit: CardSuit.Hearts },
    { number: 6, suit: CardSuit.Diamonds },
    { number: 1, suit: CardSuit.Spades },
    { number: 5, suit: CardSuit.Hearts },
    { number: 9, suit: CardSuit.Spades },
    { number: 2, suit: CardSuit.Spades },
    { number: 12, suit: CardSuit.Hearts },
    { number: 3, suit: CardSuit.Diamonds },
    { number: 12, suit: CardSuit.Clubs },
    { number: 8, suit: CardSuit.Hearts },
    { number: 6, suit: CardSuit.Spades },
    { number: 5, suit: CardSuit.Spades },
    { number: 9, suit: CardSuit.Hearts },
    { number: 13, suit: CardSuit.Clubs },
    { number: 2, suit: CardSuit.Diamonds },
    { number: 11, suit: CardSuit.Hearts },
    { number: 3, suit: CardSuit.Spades },
    { number: 8, suit: CardSuit.Spades },
    { number: 6, suit: CardSuit.Hearts },
    { number: 10, suit: CardSuit.Clubs },
    { number: 5, suit: CardSuit.Diamonds },
    { number: 13, suit: CardSuit.Diamonds },
    { number: 2, suit: CardSuit.Clubs },
    { number: 3, suit: CardSuit.Hearts },
    { number: 8, suit: CardSuit.Diamonds },
    { number: 5, suit: CardSuit.Clubs },
    { number: 13, suit: CardSuit.Spades },
    { number: 11, suit: CardSuit.Diamonds },
    { number: 8, suit: CardSuit.Clubs },
    { number: 10, suit: CardSuit.Spades },
    { number: 13, suit: CardSuit.Hearts },
    { number: 11, suit: CardSuit.Clubs },
    { number: 7, suit: CardSuit.Spades },
    { number: 10, suit: CardSuit.Hearts },
    { number: 1, suit: CardSuit.Diamonds },
    { number: 4, suit: CardSuit.Spades },
    { number: 7, suit: CardSuit.Hearts },
    { number: 4, suit: CardSuit.Diamonds },
    { number: 1, suit: CardSuit.Clubs },
    { number: 9, suit: CardSuit.Clubs },
    { number: 11, suit: CardSuit.Spades },
    { number: 12, suit: CardSuit.Diamonds },
    { number: 7, suit: CardSuit.Clubs },
    { number: 12, suit: CardSuit.Spades },
    { number: 10, suit: CardSuit.Diamonds },
    { number: 6, suit: CardSuit.Clubs },
    { number: 1, suit: CardSuit.Hearts },
    { number: 9, suit: CardSuit.Diamonds },
  ].map((c, i) => ({ stackPosition: i + 1, ...c }));
};
