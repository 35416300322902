export default [
  'alien',
  'apple',
  'arrow',
  'ball',
  'balloon',
  'banana',
  'biscuits',
  'book',
  'bottle',
  'boxing gloves',
  'bracelet',
  'bridge',
  'brocolli',
  'brush',
  'bug',
  'bus',
  'candle',
  'car',
  'card',
  'chair',
  'chalk bag',
  'chestnut',
  'clock',
  'coffee',
  'coin',
  'computer',
  'cube',
  'dice',
  'earring',
  'eyes',
  'fan',
  'fire',
  'fish',
  'fish',
  'fork',
  'glass',
  'gun',
  'hairbrush',
  'hammer',
  'hat',
  'headphones',
  'hog',
  'ice',
  'keys',
  'knife',
  'lava',
  'lemon',
  'letter',
  'light',
  'lipstick',
  'lock',
  'maracas',
  'melon',
  'moon',
  'mouth',
  'mug',
  'mushroom',
  'mustard',
  'necklace',
  'nose',
  'notebook',
  'orange',
  'paintbrush',
  'panda',
  'parrot',
  'pearl',
  'pencil',
  'phone',
  'piano',
  'pickle',
  'pie',
  'pillow',
  'pizza',
  'plant',
  'police',
  'pot',
  'potatoes',
  'rat',
  'ring',
  'rocket',
  'ropes',
  'rubber',
  'scarf',
  'shark',
  'shield',
  'shoes',
  'sock',
  'spanner',
  'spoon',
  'spring',
  'stapler',
  'sun',
  'sword',
  'table',
  'tape',
  'tissue',
  'toaster',
  'toilet',
  'train',
  'trampoline',
  'whale',
  'wheels',
  'window',
];
