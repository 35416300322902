export default [
  'sexy',
  'beautiful',
  'sparkly',
  'depressed',
  'sad',
  'happy',
  'funky',
  'sinister',
  'playful',
  'quiet',
  'ugly',
  'slow',
  'quick',
  'sticky',
  'sore',
  'annoying',
  'simple',
  'spooky',
  'creepy',
  'sour',
  'clever',
  'dumb',
  'old',
  'fresh',
  'rotten',
  'stinky',
  'small',
  'giant',
  'chunky',
  'dizzy',
  'heavy',
  'helpful',
  'useless',
  'gloomy',
  'scared',
  'brave',
  'dirty',
  'clean',
  'keen',
  'jealous',
  'silly',
  'lawful',
  'chaotic',
  'plain',
  'erotic',
  'nonsensical',
  'sensible',
  'cheerful',
  'yappy',
  'feisty',
];
